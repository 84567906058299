<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $route.query.id > 0 ? $t('elearning_config.training_calendar_modify') : $t('elearning_config.training_calendar') }}</h4>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                  <b-form  @submit.prevent="handleSubmit(submit)" @reset.prevent="reset" >
                    <b-overlay :show="unitLoad">
                    <b-row>
                      <b-col lg="6" sm="6">
                          <ValidationProvider name="Organization"  vid="org_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="3"
                                label-for="org_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                              {{ $t('globalTrans.organization') }} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                plain
                                :disabled="$route.query.id ? true : false"
                                v-model="allData.org_id"
                                :options="orgList"
                                id="org_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="6">
                          <ValidationProvider name="Fiscal Year"  vid="fiscal_year_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="3"
                                label-for="fiscal_year_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                              {{ $t('globalTrans.fiscal_year') }} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                plain
                                :disabled="$route.query.id ? true : false"
                                v-model="allData.fiscal_year_id"
                                :options="fiscalYearList"
                                id="fiscal_year_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                    </b-row>
                    <b-row style="margin-top: 20px; background: #cccccc4f" class="border border-solid" v-for="(arrq, index) in training_calendar_details" :key="index">
                    <b-col lg="2" sm="2">
                          <ValidationProvider name="Training Type"  vid="training_type_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="training_type_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                  {{$t('elearning_config.training_type')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                plain
                                v-model="arrq.training_type_id"
                                :options="trainingTypeList"
                                id="training_type_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="2" sm="2">
                            <ValidationProvider name="Training Category" vid="training_category_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="training_category_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                  {{$t('elearning_config.training_category')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                plain
                                v-model="arrq.training_category_id"
                                :options="parseInt(arrq.training_type_id) > 0 ? trainingCategoryList.filter(item => item.training_type_id === parseInt(arrq.training_type_id)) : trainingCategoryList"
                                id="training_category_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="2" sm="2">
                            <ValidationProvider name="Training Title" vid="training_title_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="training_title_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                  {{$t('elearning_config.training_title')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                plain
                                v-model="arrq.training_title_id"
                                :options="parseInt(arrq.training_category_id) > 0 ? trainingTitleList.filter(item => item.training_category_id === parseInt(arrq.training_category_id)) : trainingTitleList"
                                id="training_title_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="2" sm="2">
                          <ValidationProvider name="Number Of Days"  vid="no_of_days" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="no_of_days"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_config.no_of_days')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                              id="no_of_days"
                              v-model="arrq.no_of_days"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="2" sm="2">
                          <ValidationProvider name="Number Of Batches"  vid="no_of_batch" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="no_of_batch"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_config.no_of_batch')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                              id="no_of_batch"
                              v-model="arrq.no_of_batch"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col>
                            <b-table-simple bordered>
                                <thead>
                                    <tr>
                                        <th class="text-center">{{ $t('globalTrans.sl_no') }}</th>
                                        <th class="text-center">{{ $t('globalTrans.start_date') }}</th>
                                        <th class="text-center">{{ $t('globalTrans.end_date') }}</th>
                                        <th class="text-center">{{ $t('globalTrans.action') }}</th>
                                    </tr>
                                </thead>
                                <b-tbody>
                                  <b-tr v-for="(item, index) in arrq.training_calendar_detail_days" :key="index">
                                    <b-td class="text-center">{{ $n(index + 1) }}</b-td>
                                    <b-td class="text-center">
                                      <ValidationProvider name="Department" vid="start_date" rules="required|min_value:1">
                                        <b-form-group
                                          class="row"
                                          label-cols-sm="2"
                                          label-for="date_of_birth"
                                          slot-scope="{ valid, errors }"
                                        >
                                          <template v-slot:label></template>
                                          <flat-pickr
                                            class="form-control"
                                            v-model="item.start_date"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            :config="{
                                                minDate: fiscalYearList.find(item => item.value === allData.fiscal_year_id) ? fiscalYearList.find(item => item.value === allData.fiscal_year_id).text_en.split('-')[0] + '-01-01' : '2023-01-01',
                                                maxDate: fiscalYearList.find(item => item.value === allData.fiscal_year_id) ? fiscalYearList.find(item => item.value === allData.fiscal_year_id).text_en.split('-')[1] + '-12-31' : '2024-01-01'
                                            }"
                                          ></flat-pickr>
                                          <div class="invalid-feedback">{{ errors[0] }}</div>
                                        </b-form-group>
                                      </ValidationProvider>
                                    </b-td>
                                    <b-td class="text-center">
                                      <ValidationProvider name="Department" vid="end_date" rules="required|min_value:1">
                                        <b-form-group
                                          class="row"
                                          label-cols-sm="2"
                                          label-for="date_of_birth"
                                          slot-scope="{ valid, errors }"
                                        >
                                          <template v-slot:label></template>
                                          <flat-pickr
                                            class="form-control"
                                            v-model="item.end_date"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            :config="{
                                                minDate: fiscalYearList.find(item => item.value === allData.fiscal_year_id) ? fiscalYearList.find(item => item.value === allData.fiscal_year_id).text_en.split('-')[0] + '-01-01' : '2023-01-01',
                                                maxDate: fiscalYearList.find(item => item.value === allData.fiscal_year_id) ? fiscalYearList.find(item => item.value === allData.fiscal_year_id).text_en.split('-')[1] + '-12-31' : '2024-01-01',
                                              ...item.start_date && { minDate: item.start_date }
                                            }"
                                          ></flat-pickr>
                                          <div class="invalid-feedback">{{ errors[0] }}</div>
                                        </b-form-group>
                                      </ValidationProvider>
                                    </b-td>
                                    <b-td class="text-center">
                                      <button
                                        type="button"
                                        class="btn btn-sm btn-primary"
                                        @click.prevent="addItem(arrq)"
                                        v-show="index == arrq.training_calendar_detail_days.length - 1"
                                      >
                                        <i class="ri-add-circle-line"></i>
                                      </button>
                                      &nbsp;&nbsp;
                                      <button
                                        type="button"
                                        class="btn btn-iq-bg-danger btn-sm"
                                        @click="deleteItem(arrq, index)"
                                        v-show="arrq.training_calendar_detail_days.length > 1"
                                      >
                                        <i class="ri-delete-bin-line m-0"></i>
                                      </button>
                                    </b-td>
                                  </b-tr>
                                </b-tbody>
                            </b-table-simple>
                        </b-col>
                        <b-col md="2" class="col text-right">
                          <button type="button" class="btn btn-sm btn-primary" @click.prevent="addItemIds()" v-show="index == training_calendar_details.length-1">{{$t('globalTrans.add_more')}}</button>&nbsp;&nbsp;
                          <button type="button" class="btn btn- iq-bg-danger btn-sm" @click="deleteItemIds(index)" v-show="training_calendar_details.length > 1"><i class="ri-delete-bin-line m-0"></i></button>
                        </b-col>
                    </b-row>
                    <div class="row">
                      <div class="col-sm-12"><br></div>
                      <div class="col-sm-3"></div>
                      <div class="col text-right">
<b-button type="submit" variant="primary" class="mr-2" @click="submit">{{ $route.query.id ? $t('elearning_config.update') : $t('globalTrans.draft') }}</b-button>
                        &nbsp;
                        <router-link to="/training-e-learning-service/configuration/training-calendar" :class="'btn btn-danger text-light'">{{  $t('globalTrans.cancel') }}</router-link>
                      </div>
                    </div>
                    </b-overlay>
                  </b-form>
                </ValidationObserver>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { trainingCalendarStoreApi, trainingCalendarListByIdApi } from '../../api/routes'
import flatpickr from 'flatpickr'
export default {
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    this.allData.fiscal_year_id = this.$store.state.TrainingElearning.currentFiscalYearId
    if (this.$route.query.id) {
      this.unitLoad = true
      this.getEditingData()
    }
    if (this.$store.state.Auth.activeRoleId !== 1) {
      this.trainingCategory.org_id = this.$store.state.Auth.authUser.org_id
    }
  },
  mounted () {
    flatpickr('.date-picker', {})
    core.index()
  },
  watch: {
    'allData.org_id': function (newValue, oldValue) {
      if (newValue !== oldValue) {
        if (!this.$route.query.id) {
          this.training_calendar_details.map((task) => {
            task.training_type_id = 0
          })
        }
        this.trainingTypeList = this.getTypeList(newValue)
      }
    }
  },
  data () {
    return {
        ItemShow: false,
        saveBtnName: this.id ? this.$t('elearning_config.update') : this.$t('globalTrans.draft'),
        allData: {
          fiscal_year_id: 0,
          org_id: 0
        },
        training_calendar_details: [
          {
            training_type_id: 0,
            training_category_id: 0,
            training_title_id: 0,
            no_of_days: '',
            no_of_batch: '',
            training_calendar_detail_days: [
                {
                    start_date: new Date().toJSON(),
                    end_date: new Date().toJSON()
                }
            ],
            arrx: []
          }
        ],
        idx: 0,
        deptAr: [],
        designationList: [],
        trainingTypeList: [],
        userList: [],
        unitLoad: false
    }
  },
  computed: {
    fiscalYearList: function () {
      return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
    },
    currentFiscalYearId: function () {
      return this.$store.state.TrainingElearning.currentFiscalYearId
    },
    // trainingTypeList: function () {
    //   return this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
    // },
    trainingCategoryList: function () {
      return this.$store.state.TrainingElearning.commonObj.trainingCategoryList.filter(item => item.status === 1)
    },
    trainingTitleList: function () {
      return this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
    },
    orgList: function () {
      return this.$store.state.commonObj.organizationProfileList
    }
  },
methods: {
    getTypeList (orgId) {
      const type = this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
      if (orgId) {
        return type.filter(item => item.org_id === orgId)
      }
    },
    getCategoryList (typeId) {
      const trainingCategoryList = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.filter(item => item.status === 1)
      if (typeId) {
        return trainingCategoryList.filter(item => item.training_type_id === typeId)
      }
    },
    getTrainingTitleList (categoryId) {
      const trainingTitleList = this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
      if (categoryId) {
        return trainingTitleList.filter(item => item.training_category_id === categoryId)
      }
    },
    async getEditingData () {
      let result = null
      const loadingState = { loading: false, listReload: true }
      const params = Object.assign({}, { id: this.$route.query.id })
      result = await RestApi.postData(trainingElearningServiceBaseUrl, trainingCalendarListByIdApi, params)
      this.$store.dispatch('mutateCommonProperties', loadingState)
      if (result.success) {
          this.unitLoad = false
          const tmp = result.data
          this.allData.org_id = tmp.org_id
          this.allData.fiscal_year_id = tmp.fiscal_year_id
          this.training_calendar_details = tmp.training_calendar_details
      } else {
        this.unitLoad = false
        this.allData.org_id = 0
        this.allData.fiscal_year_id = 0
        this.training_calendar_details = []
      }
    },
    // async getAllData (fis) {
    //   this.unitLoad = true
    //   let result = null
    //   const loadingState = { loading: false, listReload: true }
    //   const params = Object.assign({}, { fiscal_year_id: parseInt(fis) })
    //   result = await RestApi.postData(trainingElearningServiceBaseUrl, trainingCalendarListByFiscalApi, params)
    //   this.$store.dispatch('mutateCommonProperties', loadingState)
    //   if (result.success) {
    //       this.training_calendar_details = []
    //       const tmp = result.data
    //       if (tmp) {
    //         this.training_calendar_details = tmp.training_calendar_details
    //         this.idx = tmp.id
    //         this.unitLoad = false
    //       } else {
    //         this.idx = 0
    //         this.training_calendar_details = []
    //         this.addItemIds()
    //         this.unitLoad = false
    //       }
    //   } else {
    //         this.idx = 0
    //         this.training_calendar_details = []
    //         this.addItemIds()
    //         this.unitLoad = false
    //   }
    // },
    deleteItem (arrq, index) {
      const deletedDepartmentId = arrq.training_calendar_detail_days[index].start_date
      arrq.training_calendar_detail_days.splice(index, 1)
      arrq.arrx = arrq.arrx.filter(id => id !== deletedDepartmentId)
    },
    addItem (arrq) {
      const newDepartmentId = parseInt(arrq.start_date)
      const validate = arrq.training_calendar_detail_days.some(data => data.start_date === newDepartmentId)

      if (!validate) {
        arrq.training_calendar_detail_days.push({
            start_date: new Date().toJSON(),
            end_date: new Date().toJSON()
        })
        arrq.arrx.push(newDepartmentId)
      }
    },
    deleteItemIds (index) {
      this.training_calendar_details.splice(index, 1)
    },
    addItemIds () {
       const success = this.$refs.form.validate()
          if (success) {
        this.training_calendar_details.push({
                  training_type_id: 0,
                  training_category_id: 0,
                  training_title_id: 0,
                  no_of_days: '',
                  no_of_batch: '',
              training_calendar_detail_days: [
                  {
                      start_date: new Date().toJSON(),
                      end_date: new Date().toJSON()
                  }
              ],
              arrx: []
            })
             } else {
          }
    },
    async submit () {
        this.unitLoad = true
        this.$store.dispatch('mutateCommonProperties', { loading: true })
        let result = null
        let params = null
        const loadinState = { loading: false, listReload: true }
        if (this.$route.query.id) {
          if (parseInt(this.$route.query.revise) > 0) {
            params = Object.assign({}, { training_calendar_details: this.training_calendar_details, org_id: this.allData.org_id, fiscal_year_id: this.allData.fiscal_year_id, id: this.idx, revise_check: 1 })
          } else {
            params = Object.assign({}, { training_calendar_details: this.training_calendar_details, org_id: this.allData.org_id, fiscal_year_id: this.allData.fiscal_year_id, id: this.$route.query.id })
          }
        } else {
          if (this.idx > 0) {
            params = Object.assign({}, { training_calendar_details: this.training_calendar_details, org_id: this.allData.org_id, fiscal_year_id: this.allData.fiscal_year_id, id: this.idx })
          } else {
            params = Object.assign({}, { training_calendar_details: this.training_calendar_details, org_id: this.allData.org_id, fiscal_year_id: this.allData.fiscal_year_id })
          }
        }

        const arr = params.training_calendar_details.map(item => item.training_title_id)
        if (arr.every(i => i !== 0)) {
          result = await RestApi.postData(trainingElearningServiceBaseUrl, trainingCalendarStoreApi, params)
        }
        this.$store.dispatch('mutateCommonProperties', loadinState)

        if (result.success) {
            this.unitLoad = false
            this.$store.dispatch('mutateCommonProperties', { hasDropdownLoaded: false })
            this.$store.dispatch('HospitalDisabilityService/mutateCommonObj', { hasDropdownLoaded: false })
            this.$toast.success({
            title: this.$t('globalTrans.success'),
            message: this.id ? this.$t('elearning_config.update_msg') : this.$t('globalTrans.save_msg'),
            color: '#D6E09B'
            })

            this.$router.push('/training-e-learning-service/configuration/training-calendar')
        } else {
            this.unitLoad = false
            this.$refs.form.setErrors(result.errors)
        }
    }
  }
}
</script>
<style>
select option:disabled {
    color: green !important;
    font-weight: bold;
}
</style>
